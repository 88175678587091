.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul li {
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.8px;
  color: #283c62;
  cursor: pointer;
}

li button {
  font-family: "Tajawal", Sans-serif;
  font-weight: 500;
  font-size: 1.5em;
}

#download-app-section {
  background-image: url("./assets/new-hand-phone.png");
  background-position: 96% 50%;
  background-repeat: no-repeat;
  background-size: 30%;
}

button,
button:focus {
  outline: none;
}

/* Media query to hide background image on mobile devices */
@media only screen and (max-width: 1024px) {
  #download-app-section {
    background-image: none; /* Hides the background image on mobile devices */
  }
}
